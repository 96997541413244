import { Breadcrumbs, Button, Emoji, PageMeta } from 'components/common';
import Link from 'next/link';

// export const getServerSideProps = async () => ({ props: {} });


export default function NotFoundPage() {
  return (
    <div className="px-4 py-10 flex flex-col gap-6">
      <PageMeta/>
      <Breadcrumbs path={[ { title: 'главная', href: '/' }, { title: '404' } ]}/>
      <div className="gap-8 flex flex-col md:flex-row">
        <div>
          <img alt="" className="rounded-lg" width="200" src={'/oops.gif'}/>
        </div>
        <div className="flex-1 flex flex-col gap-4">
          <div className="flex gap-2 text-2xl">
            <span>страница не найдена</span>
            <Emoji size="7">1f974</Emoji>
          </div>
          <div>возможно, она была перемещена или вы ошиблись, когда вводили адрес</div>
          <div>хотите посмотреть наши букеты?</div>
          <div>
            <Link aria-label="Каталог" href="/">
              <Button aria-label="Каталог" sx="text-sm" color="gray">Покажите</Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
